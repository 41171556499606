body{
    font-family:avenir, sans-serif;
}
h1{
    font-size:27px;
    font-family:kefa, serif;
}
h2{
    font-size:24px;
    font-family:kefa, serif;
}
h3{
    font-size:21px;
    font-family:kefa, serif;
}
h3:first-child{
    margin-top:0px;
}
.bold{
    font-weight: bold;
}
p:first-of-type{
    margin-top:0px;
}
div.centered{
    text-align:center;
}
.chapterBlock{
    border-radius:10px;
    background-color: #faf6f2;
    padding:15px;
    color:rgb(55, 55, 55);
}
.top-block-spacing{
    margin-top:30px !important;
}
.bottom-block-spacing{
    margin-bottom:30px !important;
}
.chapterBlock span{
    white-space:nowrap;
}
.chapter-link{
    clear:both;
    text-align: center;
    margin-bottom:25px;
}
.row{
    display: flex;
}
.row.divider{
    border-bottom:1px solid rgb(55, 55, 55);
}
.row.bottom-space{
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.giant-steps-row{
    max-width:100%;
}
.giant-steps-row h4{
    margin-bottom: 6px;
}
.right-channel{
    padding-left:20px;
}
.six-channel{
    flex:6; 
}
.five-channel{
    flex:5;
}
.four-channel{
    flex:4
}
.left-channel{
    padding-right:15px;
}

.fullChannel{
    flex:1;
}
.leftChannel{
    flex:6; 
}
.rightChannel{
    flex:4
}
.leftChannel.flip{
    flex:4;
}
.rightChannel.flip{
    flex:6
}
.leftChannel p{
    margin-top:0px;
}

.one-flex-column{
    flex:1;
    padding:5px;
}

.one-third-channel{
    flex:1;
    padding:5px;
}
.one-third-channel .hkmap{
    margin:0;
    margin-left:-40px;
}
.giant-steps-column{
    flex:1
}

.with-side-bar .right-channel{
}
.with-side-bar .right-channel div{
    text-align:center;   
}

.side-bar{

}
.side-bar div{
    text-align:center;
}
.side-bar-block{
    margin-bottom:15px;    
    background-color:#e2ddd8;
    padding:25px 10px 15px 10px;
    border-radius: 10px;
}
.side-bar-block div{
    margin-bottom:20px;
}
.side-bar-block:last-child{
    margin-bottom:0px;
}

.rightChannel svg{
    display:block;
    margin:auto;
}
.caption{
    text-align: left;
    margin:auto;
    margin-top:10px;
    width:85%;
    font-size:17px;
}
.rightChannel .caption ul{
    padding-left: 0px;
}
#chapter-one .single-key svg{
    margin:auto;
}
#chapter-one .two-chord svg{
    margin:auto;
}
#chapter-one .base-map svg{
    margin:auto;
}

#chapter-six li{
    margin-bottom:8px;
}
.leftChannel .hkmap{
    display: block;
    margin:auto;
}
.caption-title{
    text-align: center;
    text-decoration: underline;
    margin-bottom:10px;
    font-size:20px;
    font-family:kefa;
}
.helper-block{
    margin-bottom:15px;
}
.helper-block:last-child{
    margin-bottom:0px;
}
ul.exercise-steps{
    padding-right:75px;
    margin-bottom: 50px;
}
.exercise-steps li{
    margin-bottom:10px;
}

.previous-chapter{
    margin-right:20px;
}
.chapter-navigation-link{
    margin:15px 0 15px 0;
}
.chapter-shell{
    margin: auto;
    margin-top: 110px;
    max-width: 1000px;
    font-size:18px;
}
