.accidental{
    transform: translate(6px,0px);
    font-size: 14px;
}
.key-builder-exercise .accidental{
    transform: translate(12px,0px);
    font-size: 16px; 
}
.center-hex .accidental{
    transform: translate(11px,0px); 
}
g.accidental-included .accidental-split-base{
    transform: translate(-4px,0px);
}


  /*  accidental-split accidental-included accidental-split-base accidental */