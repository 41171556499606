.hkmap{
    overflow:visible;
}
.HKMKey{
  stroke-width: 0px;
}
.neighbors .HKMKey{
}
.neighbors .HKMNote{
  pointer-events: none
}

.HKMKey.selected, .HKMKey.hilited{
  opacity:1;
}


.grouping .HKMKey{
  stroke-width: 1px;
}
.HKMKey polygon{
  fill:rgb(206 204 202);
  stroke: rgb(99,99,99);
  stroke-width:0.5
}
.HKMKey.selected polygon{
  fill:rgb(160,160,160);
  stroke: rgb(255, 255, 255);
}
.HKMKey.selected.hilited polygon{
  fill:rgb(88, 88, 88);
}
.HKMKey.hilited polygon {
  fill:rgb(97, 97, 97);
}
.field-key-label{
    fill: rgb(172 165 160);
    stroke:none;
    font-weight: bold;
    text-anchor:middle;
    cursor: pointer;
    pointer-events: none;
  }
  .HKMKey.selected .field-key-label, .HKMKey.hilited .field-key-label{
    fill: #fff;
}

.chord-helper .HKM-note-circle{
  stroke:none;
}
.chord-helper .HKM-note-circle.hilited.selected{
  fill:#2196F3;
  stroke:none;
}
.chord-helper .HKM-note-circle.hilited{
  fill:#8b8b8b;
  stroke:none;
}
.chord-helper .HKM-note-circle.selected{
  fill:#3d3d3d;
  stroke:none;
}




.giant-steps-animation .HKM-note g{
    transition: all 1.5s ease;
  }
.giant-steps-animation .field-hexagon{
    transition: all 1.5s linear;
  }

  .HKM-note-circle{
    fill:rgb(196, 196, 196);
    opacity:1;
    stroke:white;
    stroke-width:1px;
  }
  .HKM-note-circle.selected{
    fill:rgb(120, 120, 120);
  }
  .HKM-note-name{
    pointer-events: none;
    cursor: pointer;
    fill: rgb(255, 255, 255);
    font-weight:bold;
    text-anchor:middle;
    font-family:kefa, serif;
  }
  .dividing-line{
    fill:#2196F3;
  }
  .chord-label {
    font-size:20px;
    fill:rgb(54, 53, 53);
  }
  .interval-arrow-inner.selected{
    fill:#2196F3;
  }