
  .HKM-note-circle.hilited, .HKM-note-circle.hilited.selected{
    fill:#2196F3;
    stroke:#2196F3;
  } 

  .interval-component.interval-wrapper .interval-arrow-inner.selected {
    fill:#2196F3;
  }
  .HKM-interval-label.selected.one text, .HKM-interval-label.selected.two text{
    fill:#2196F3;
    font-weight:bold;
  }