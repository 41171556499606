.top-nav{
  position:fixed;
  top:0px;
  left:0px;
  background-color:#292828;
  width:100%;
  height:75px;
  border-bottom:1px solid rgb(55, 55, 55);
  z-index:10;
  padding:10px;
  color:white;
}
.top-nav-inner{
  max-width: 1000px;
  margin: auto;
}
.top-nav h1{
  margin-top:0;
}
.top-nav button{
  margin-left:20px;
}
.toc-toggle{
  cursor: pointer;
}



.arrow {
    position:relative;
    left:10px;
    top:10px;
  }
  
  .arrow::before,
  .arrow::after {
    position: relative;
    content: '';
    display: block;
    width: 10px;
    height: 2px;
    background: rgb(255, 255, 255);
    transition: 0.3s ease-in-out;
  }
  
  .arrow::before {
    transform: rotate(45deg);
  }
  
  .arrow::after {
    left: 6px;
    top: -2px;
    transform: rotate(-45deg);
  }
  
  
  .toc-toggle.open .arrow::before {
    transform: rotate(-45deg);
  }
  
  .toc-toggle.open .arrow::after {
    transform: rotate(45deg);
  }