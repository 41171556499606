a{
    color:rgb(55, 55, 55);
}
.right{
    text-align:right;
    margin-right:20px;
}
.tm{
    font-size:10px;
}
.no-break{
    white-space:nowrap;
}
.footer{
    border-top:1px solid black;
    padding-top:20px;
    text-align:center;
    margin-top:20px;
    margin-bottom:20px;
    font-size:12px;
}
.footer .copyright-notice{
    margin:auto;
    font-size:11px;
    width:60%;
}

.toc{
    position:fixed;
    width:100%;
    top:-800px;
    left:0px;
    z-index:5;
    display:block;
    transition: all 2s ease 0s;     
}
.toc.open{
    top:95px;
}
.toc-channel{
    flex:1;
    padding:10px;
}
.toc-inner{
    margin:auto;
    max-width:1000px;
    background-color:rgb(230, 226, 224); 
 
    border-bottom-right-radius:15px; 
    border-bottom-left-radius: 15px;
    padding: 10px 10px 15px 10px; 
    border:1px solid rgb(71, 68, 65); 
}
.toc ol {
    margin-top:0px;
    margin-left:0px;
    padding:10px;
}
.toc a{
    text-decoration: none;
    font-size:16px;
}
.toc li{
    margin: 8px 10px 8px 10px;
}
.toc .close-button{
    cursor: pointer;
}
.shadow{
    -webkit-filter: drop-shadow( 0.5px 0.5px 0.25px rgba(51, 51, 51, 0.9));
    filter: drop-shadow( 0.5px 0.5px 0.25px rgba(51, 51, 51, 0.9));
}

.hexkeyhelper .shadow{
    -webkit-filter: drop-shadow( 2px 2px 1px rgba(51, 51, 51, 0.4));
    filter: drop-shadow( 0.5px 0.5px 0.5px rgba(31, 31, 31, 0.7));
}
.course{
    min-height:1000px;
    max-width: 1000px;
    margin:auto;
    padding:0px 15px 0px 15px;
}
.course-nav{
    font-family: avenir, sans-serif;
    font-size:16px;
    margin-top:35px;
    margin-bottom:35px
}
.course-nav .next{
    text-align: right;
}
.course-nav a{
    color:#2196F3;
}