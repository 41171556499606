.key-select{
    font-size:20px;
    margin-bottom:15px;
}
.key-builder-exercise .message{
    margin-top:25px;
}

.helpers{
    font-size:20px;
    text-align:center;
}
.note-select{
    font-size: 20px;
}
.message{
    margin-top:25px;
    margin-bottom:20px;
    text-align: center;
}
.show-hide-toggler{
    margin-bottom:20px;
}