.note-buttons{
    margin-top:40px;
}
.note-buttons div{
    text-align:center;
}
.note-buttons.sharp .second-row{
    margin-left:-50px;
}
.note-buttons.flat .second-row{
    margin-left:50px;
}
.note-buttons button{
    font-size:20px;
    width:60px;
    cursor:pointer;
}