.musical-alphabet div{
    text-align:center;
    font-size:18px;
}
.musical-alphabet-note{
    margin-right:10px;
}
.musical-alphabet-note.reference-note{
    font-weight: bold;
    font-size:20px;
}
.musical-alphabet-note.selected-note{
    font-weight: bold;
    color:#2196F3;

    font-size:20px;
}