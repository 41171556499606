.interval-component .HKMKey{
    fill:none;
    stroke-width: 0px;
}
.HKM-interval-label.one text{
  fill:rgb(138, 137, 137);
}
.HKM-interval-label.two text{
  fill:rgb(62, 61, 61);
}
.interval-exercise .HKM-interval-label{
  display:none;
}

.interval-component .interval-arrow-inner.one{
  fill:rgb(138, 137, 137);
}
.interval-component .interval-arrow-inner.two{
  fill:rgb(62, 61, 61);
}
.interval-component.chord-interval-base .HKMKey{
  fill:rgb(187, 185, 185);
  stroke:rgb(187, 185, 185);
  stroke-width:8px;
}
